import config from "../config";
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'
import { red } from "@mui/material/colors";
const apiUrl = config.apiGateway.URL + config.apiGateway.apiPrefix
const csrfUrl = config.apiGateway.URL


export const Auth = {
    register: ({ email, password }) => {
        return axios({
            withCredentials:true,
            withXSRFToken:true,
            method:'post',
            url:`${apiUrl}/register`,
            data:{
                'email':email,
                'password':password
            },
            headers:{
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
            }
        })
    },
    verifyEmail: ({ token }) => {
        return axios({
            withCredentials:true,
            withXSRFToken:true,
            method:'post',
            url:`${apiUrl}/confirm-email`,
            data:{
                'token':token
            },
            headers:{
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        })
    },
    resetPass: ({ email }) => {
        return axios({
            withCredentials:true,
            withXSRFToken:true,
            method:'post',
            url:`${apiUrl}/reset-password`,
            data:{
                'email':email
            },
            headers:{
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
            }
        })
    },
    resetPassConfirm: ({ code, password }) => {
        return axios({
            withCredentials:true,
            withXSRFToken:true,
            method:'post',
            url:`${apiUrl}/reset-password-confirm`,
            data:{
                'code':code,
                'password':password
            },
            headers:{
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
            }
        })
    },
    getCsrfToken: () => {
        axios.get(`${csrfUrl}/sanctum/csrf-cookie`, {
            withCredentials : true,
            withXSRFToken:true,
        })
    },
    login: ({ email, password }) => {
        return axios({
            withCredentials:true,
            withXSRFToken:true,
            method:'post',
            url:`${apiUrl}/login`,
            data:{
                'email':email,
                'password':password
            },
            headers:{
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
            }
        })
    },
    logout: () => {
        return axios({
            withCredentials:true,
            withXSRFToken:true,
            method:'post',
            url:`${apiUrl}/logout`,
            headers:{
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
            }
        })
    },
    redirectToLogin: () => {
        window.location.href = '/login'
    },
    redirectToPayment: () => {
        window.location.href = '/upgrade'
    },
    checkAuth: () => {
        return Promise.resolve();  
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            // do not return Promise.reject here or remove token as it WILL cause logout
        }
        return Promise.resolve();
    },
    getPermissions: params => Promise.resolve(),
};

const setCookie = (name, value, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString()
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path + '; SameSite=Strict;'
}

export const getCookie = (name) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '')
}

const deleteCookie = (name, path) => {
  setCookie(name, '', -1, path)
}

