import { useEffect, useState } from 'react';
import { getList } from "../../common/BackendProvider.jsx";
import {toast, ToastContainer} from "react-toastify";
import { format } from "d3";
import Table from '../../layout/Table.jsx';
import { SearchBar } from './SearchBar.jsx';
import { Pagination } from '../../layout/Pagination.jsx';

export const Companies = () => {
    const[companies, setCompanies] = useState([])
    const[page, setPage] = useState(1)
    const[pages, setPages] = useState(1)
    const[loading, setLoading] = useState(true)
    const[filter, setFilter] = useState({"hasprice": 0, "sector": 0, "industry": 0, "region": 0, "size": 0})

    const loadCompanies = () => {
        setLoading(true)
        getList("companies", {filter: filter, pagination:{page:page, perPage:100}}).then((response) => {
            setCompanies(response.data.companies)
            setPages(response.data.pages)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading companies", {})
                setLoading(false)
            }
        )
    }

    useEffect(() => {
        loadCompanies()
    },[filter, page])

    const handleSectorChange = (sector) => {
        setFilter({...filter, sector: sector})
    }

    const handleIndustryChange = (industry) => {
        setFilter({...filter, industry: industry})
    }

    const handleRegionChange = (region) => {
        setFilter({...filter, region: region})
    }

    const handleSizeChange = (size) => {
        setFilter({...filter, size: size})
    }

    const handlePageChange = (page) => {
        setPage(page)
    }

        const getColor = (d) => {
        if (d > 5) return 'bg-[#00CC00]';
        if (d > 2) return 'bg-[#7FFF00]';
        if (d > -1) return 'bg-[#FFFF00]';
        if (d > -4) return 'bg-[#FF7F00]';
        return 'bg-[#FF0000]';
    };

    const getComponent = (d) => {
        const color = getColor(d);
        if (d > 0) {
            d = "+" + d
        }
        return (
        <div className={`w-8 h-8 m-auto rounded-full flex justify-center ${color} text-center items-center`}>
            {d}
        </div>
        ) 
    }

    return (
        <div className="lg:p-8 p-2">
            <div className="text-2xl font-bold mb-8">Companies</div>
            <div className="bg-white rounded-lg">
                <div className="p-4">
                <SearchBar 
                    setSector={handleSectorChange}
                    setIndustry={handleIndustryChange}
                    setRegion={handleRegionChange}
                    setSize={handleSizeChange}
                />
                </div>
                <div className="">
                    <div className="px-4 grow min-w-0">
                    <Table
                        className="lg:w-full table-auto"
                        columns={["Name", "Symbol", "Last Price", "Total Score", "ESG Score", "Daily High", "Daily Low", "Volume"]}
                        keys={[
                            {name: 'name', type: 'text', format: (d) => (d), class: "text-left", link: "/company/{symbol}"},
                            {name: 'symbol', type: 'text', format: (d) => (d), class: "text-center", link: "/company/{symbol}"},
                            {name: 'last_price', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"},
                            {name: 'total_score', type: 'text', format: (d) => format(",.0f")(d), class: "text-right", component: getComponent, link: "/company/{symbol}"},
                            {name: 'esg_score', type: 'text', format: (d) => format(",.0f")(d), class: "text-right", link: "/company/{symbol}"},
                            {name: 'high_24h', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"},
                            {name: 'low_24h', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"},
                            {name: 'volume_24h', type: 'text', format: (d) => format(",.0f")(d), class: "text-right", link: "/company/{symbol}"}

                        ]}
                        data={companies}
                        readOnly={true}
                        loading={loading}
                    />
                    </div>
                    <div className="mb-4 justify-left mt-4">
                        <Pagination page={page} pages={pages} onPageChange={(page) => handlePageChange(page)} />
                    </div>
                </div>
            </div>
        </div>
    )
}