const cx = require('classnames');
import { useEffect, useState } from "react";
import { getOne} from '../common/BackendProvider';
import { ToastContainer, toast } from "react-toastify";
import {Checked, Blocked} from '../layout/images/GeneralIcons';
import { text } from "d3";

const Tile = ({ url, title, amount, subtitle, features, current }) => {

    return (
        <div className="flex border-b lg:border-b-0 px-6 py-4 justify-between">
            <div>
                <div className="text-general-100 mt-2 mb-6">GBP <span className="text-4xl font-bold">{amount}</span> /month</div>
                <div className="text-general-100 text-3xl font-bold mb-4">{title}</div>
                <div className="text-general-100 text-sm mb-4">{subtitle}</div>
                <hr className="text-general-40 mb-4"/>
                <div className="mb-6">
                    {features.map((feature) => {
                        return (
                            <div className="flex items-center mb-2">
                                {feature.checked ? <Checked/> : <Blocked/>}
                                <div className="ml-2">{feature.text}</div>
                            </div>
                        )
                    })}
                   
                </div>
                <div>
                    {current && 
                    <div className="border border-primary-100 text-primary-100 rounded-lg p-1 text-center w-full">Current Plan</div>}
                    {current ||
                    <div className="w-full"><a href={url} className="bg-primary-100 text-white rounded-lg p-2 text-center w-full block">Select Plan</a></div>}
                </div>
            </div>
        </div>
    );
}

export const UpgradeSubscription = () => {
    const [url, setUrl] = useState("")
    const [loading, setLoading] = useState(true)
    useEffect(() => {getOne("payment_link", {id: 1}).then((response) => {
            setUrl(response.data.payment_url)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading user data", {})
                setLoading(false)
            }
        )
    },[])

    const featuresFree = [
        {text: "Unlimited company lookups", checked: false},
        {text: "Priority Support", checked: false},
        {text: "Email Support", checked: true}
    ]

    const featuresFull = [
        {text: "Unlimited company lookups", checked: true},
        {text: "Priority Support", checked: true},
        {text: "Email Support", checked: true}
    ]

    return (
        <div className="p-2 lg:p-8">
            <div className="text-2xl font-bold mb-8 text-center">Upgrade</div>
            <div className="grid lg:grid-cols-2 gap-1 rounded-lg bg-white">
                <Tile title="Free" amount="0.00" subtitle="Free Demo" features={featuresFree} url={url} current={true}/>
                <Tile title="Full" amount="7.95" subtitle="Full Access" features={featuresFull} url={url} current={false}/>
            </div>
        </div>
    )
};