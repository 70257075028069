import dataProvider from './DataProvider';
import {Auth} from './AuthProvider';
import querystring from 'query-string';
import axios from 'axios';

import config from "./../config";

const apiUrl = config.apiGateway.URL + config.apiGateway.apiPrefix;


export function getList (resource, params, props) {
    return new Promise((resolve, reject) => {
        let sort = {field: '', order: ''}
        let pagination = {page: 0, perPage: 0}
        if (params !== undefined && params.sort !== undefined) {
            sort = params.sort;
        }
        if (params !== undefined && params.pagination !== undefined) {
            pagination = params.pagination;
        }
        const { page, perPage } = pagination;
        const { field, order } = sort;
        const filter = params?.filter ? Object.fromEntries(
            Object.entries(params.filter).filter(([_, value]) => value !== null)
        ) : {};
        const sort2 = params?.sort ? Object.fromEntries(
            Object.entries(params.sort).filter(([_, value]) => value !== null)
        ) : {};
        const query = {
            sort: JSON.stringify({field:field, order:order}),
            page: page,
            limit: perPage,
            filter: JSON.stringify(filter),
        }
        const qs = new URLSearchParams(filter).toString() + new URLSearchParams(params?.range).toString() + new URLSearchParams(params?.sort).toString()
        const url = `${apiUrl}/${resource}?${qs}&${querystring.stringify(query)}`;

        axios.get(url, {
                withCredentials: true, 
                withXSRFToken:true, 
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    }
                }
        ).then(res => {
            resolve(res)
        })
        .catch(err => {
            if (err.response.status === 401) {
                Auth.redirectToLogin()
            }
            reject(err)
        })
    });
}


export function getOne (resource, params, props) {
    return new Promise((resolve, reject) => {
        axios.get(`${apiUrl}/${resource}/${params.id}`, {
                withCredentials: true, 
                withXSRFToken:true, 
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    }
        }).then(res => {
            resolve(res)
        }).catch(err => {
            if (err.response.status === 401) {
                Auth.redirectToLogin()
            }
            if (err.response.status === 402) {
                Auth.redirectToPayment()
            }
            reject(err)
        })
    });
}

export function update (resource, params, props) {
    return new Promise((resolve, reject) => {
        axios.put(`${apiUrl}/${resource}/${params.id}`, params.data, {
                withCredentials: true, 
                withXSRFToken:true, 
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    }
        }).then(res => {
            resolve(res)
        }).catch(err => reject(err));
    });
}

export function create (resource, params, props) {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/${resource}`, params.data, {
                withCredentials: true, 
                withXSRFToken:true, 
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    }
        }).then(res => {
            resolve(res)
        }).catch(err => reject(err));
    });
}

export function deleteOne (resource, params, props) {
    return new Promise((resolve, reject) => {
        axios.delete(`${apiUrl}/${resource}/${params.id}`, {
                withCredentials: true, 
                withXSRFToken:true, 
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    }
        }).then(res => {
            resolve(res)
        }).catch(err => reject(err));
    });
}