import { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import {getList} from '../../../../common/BackendProvider';
import {toast, ToastContainer} from "react-toastify";
import { map } from 'd3';

const FirstDayOfMonthTickFormatter = (unixTime) => {
  const date = new Date(unixTime);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  
  const firstDayOfMonth = new Date(year, month, 1);

  return `${year}-${month + 1}-${day}`;
};

export const StockChart = ({company}) => {
    const[stockData, setStockData] = useState([])
    const[interval, setInterval] = useState("1d")
    const[loading, setLoading] = useState(true)
    const intervals = ["1d", "1w", "1m", "3m", "1y"]
    useEffect(() => {
        setLoading(true)
        getList("companies/" + company.symbol + "/stock" , {filter:{'interval': interval}}).then((response) => {
            setStockData(response.data.stock_data)
            
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading stock data", {})
                setLoading(false)
            }
        )
    },[interval, company])
    return (
        <>
            {map(intervals, (i) => {
                return <button className={`border bg-white hover:bg-blue-700 text-blue-600 hover:text-white border-blue-500 py-0 px-6 rounded ml-4 mb-2 ${interval === i ? "text-blue-600 border-blue-500" : "border-transparent"}`} onClick={()=>setInterval(i)}>{i}</button>
            })}
            <AreaChart width={815} height={250} data={stockData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <XAxis dataKey="timestamp"
                    domain={['dataMin', 'dataMax']} minTickGap={50} 
                    tickFormatter={FirstDayOfMonthTickFormatter}/>
                <YAxis domain={['dataMin', 'dataMax']}/>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area type="monotone" dataKey="close" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
            </AreaChart>
            
        </>
    )
}