import * as React from 'react';
import { Panel } from '../../../layout/Panel';
import PieChartWithNeedle from '../../../layout/components/PieChartWithNeedle';
import HorizontalBarChart from './../../../layout/components/HorizontalBarChart';

const Tile = ({title, description, value, scoreValue, industryAverage, industryMin, industryMax}) => {
    return(
        <div className="p-4 flex-1 bg-white text-gray-600 rounded border border-primary-30 shadow-shadow-base text-left">
            <div className="flex flex-col">
                <div>
                <HorizontalBarChart name={description} value={scoreValue} width={200} height={100}/> 
                </div>
                <div className="ml-2">
                    <div className="p-2 text-xl font-bold">{title}: {value}</div>
                    <p className="pl-2 text-sm text-gray-600 mb-4">{description}</p>
                </div>
            </div>
        </div>
    )
}


export const EsgTab =({esgScore, symbol}) => {

    // 0-AMZN 1-GOOG
    const riskData = [
        [
            { name: '2017', scope1: 1.09, scope2: 1.09, scope3: 1.09, scope123: 1.09 },
            { name: '2018', scope1: 1.09, scope2: 1.09, scope3: 1.10, scope123: 1.10 },
            { name: '2019', scope1: 1.09, scope2: 1.09, scope3: 1.10, scope123: 1.10 },
            { name: '2020', scope1: 1.09, scope2: 1.09, scope3: 1.11, scope123: 1.12 },
            { name: '2021', scope1: 1.09, scope2: 1.09, scope3: 1.12, scope123: 1.12 },
            { name: '2022', scope1: 1.10, scope2: 1.09, scope3: 1.12, scope123: 1.13 },
            { name: '2023', scope1: 1.10, scope2: 1.09, scope3: 1.13, scope123: 1.14 },
            { name: '2024', scope1: 1.10, scope2: 1.09, scope3: 1.13, scope123: 1.14 },
            { name: '2030', scope1: 1.11, scope2: 1.09, scope3: 1.15, scope123: 1.18 },
            { name: '2040', scope1: 1.13, scope2: 1.09, scope3: 1.20, scope123: 1.24 },
            { name: '2050', scope1: 1.16, scope2: 1.09, scope3: 1.25, scope123: 1.32 },
        ],[
            { name: '2017', scope1: 1.09, scope2: 1.09, scope3: 1.09, scope123: 1.09 },
            { name: '2018', scope1: 1.09, scope2: 1.09, scope3: 1.10, scope123: 1.10 },
            { name: '2019', scope1: 1.09, scope2: 1.09, scope3: 1.10, scope123: 1.10 },
            { name: '2020', scope1: 1.09, scope2: 1.10, scope3: 1.10, scope123: 1.11 },
            { name: '2021', scope1: 1.09, scope2: 1.10, scope3: 1.10, scope123: 1.11 },
            { name: '2022', scope1: 1.09, scope2: 1.10, scope3: 1.10, scope123: 1.11 },
            { name: '2023', scope1: 1.09, scope2: 1.10, scope3: 1.10, scope123: 1.11 },
            { name: '2024', scope1: 1.09, scope2: 1.10, scope3: 1.11, scope123: 1.12 },
            { name: '2030', scope1: 1.09, scope2: 1.11, scope3: 1.11, scope123: 1.14 },
            { name: '2040', scope1: 1.09, scope2: 1.13, scope3: 1.13, scope123: 1.17 },
            { name: '2050', scope1: 1.09, scope2: 1.16, scope3: 1.15, scope123: 1.21 },
 
        ]
    ]
    const data = [
        { name: 'A', value: 20, color: '#FF0000' }, // Red
        { name: 'B', value: 20, color: '#FF7F00' }, // Orange
        { name: 'C', value: 20, color: '#FFFF00' }, // Blue
        { name: 'D', value: 20, color: '#7FFF00' }, // Light Green
        { name: 'E', value: 20, color: '#00CC00' }, // Green
    ];
     // convert range 0-100 to -2 to 2
    const envScore = esgScore.environment 
    const socialScore = esgScore.social
    const governanceScore = esgScore.governance 
    const totalScore = envScore + socialScore + governanceScore
    const indicators = [
        {
            "title": "Environmental",
            "description": "Environmental Score",
            "value": envScore,
            "scoreValue": envScore,
        },{
            "title": "Social",
            "description": "Social Score",
            "value": socialScore,
            "scoreValue": socialScore
        },{
            "title": "Governance",
            "description": "Governance Score",
            "value": governanceScore,
            "scoreValue": governanceScore
        }]
   
    let graphData = []
    if (symbol == "GOOGL") {
        graphData = riskData[1]
    } else if (symbol == "AMZN") {
        graphData = riskData[0]
    }
    return(
        <div className="flex flex-row gap-4"> 
            <div className="w-2/5 h-full">
                <Panel>
                    <h2 className="text-center text-2xl font-bold text-gray-600">ESG Score: {totalScore}</h2>
                    <PieChartWithNeedle data={data} width={240} height={240} value={(totalScore *.24 + 2) *25}/>                
                   
                </Panel>
            </div>
            <div className="w-full grid grid-cols-3 gap-4">
                {indicators.map((indicator, index) => {
                    return <Tile 
                        key={index} 
                        title={indicator.title} 
                        description={indicator.description}
                        value={indicator.value} 
                        scoreValue={indicator.scoreValue}
                        icon={indicator.icon}
                        industryAverage={indicator.industryAverage}
                        industryMin={indicator.industryMin}
                        industryMax={indicator.industryMax}
                        />
                })}
            </div>
        </div>
    )
}
