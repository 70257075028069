import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import {getOne, create} from '../../common/BackendProvider';
import Tabs from '../../layout/Tabs';
import {Panel} from '../../layout/Panel';
import { StarInactive, StarActive } from '../../layout/images/Stars';
import { EsgTab } from './tabs/EsgTab';
import { RiskTab } from './tabs/RiskTab';
import { FinanceTab } from './tabs/FinanceTab';
import { OverviewTab } from './tabs/overview';
import { NewsTab } from './tabs/NewsTab';

const tabs = ["Summary", "ESG", "Financial", "Risk", "Description", "News Atricles"]
export const CompanyPage =() => {
    const[company, setCompany] = useState({})
    const[esgScore, setEsgScore] = useState({})
    let location = useLocation()
    const[loading, setLoading] = useState(true)
    const[isFavorite, setIsFavorite] = useState(false)
    const[tab, setTab] = useState(0)
    let { symbol } = useParams(); 
    useEffect(() => {
        setLoading(true)
        getOne("companies", {id: symbol}).then((response) => {
            setCompany(response.data.company)
            setEsgScore(response.data.esg_score)
            localStorage.setItem('credits', response.data.usage_credits);
            setIsFavorite((response.data.company.favorite.length)>0 ? true : false)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading companies", {})
                setLoading(false)
            }
        )
    },[location])
    
    const handleToggleFavorite = () => {
        create("watchlist", {data: {symbol: symbol}}).then((response) => {
            toast.success(response.data.message, {})
            setIsFavorite(!isFavorite)
        }).catch(
            (e) => {
                toast.error("Error while adding company to favorites", {})
            }
        )
    }

    if (company === null) {
        return <div>Company not found</div>
    }

    return (
      <div className="p-2 lg:p-8">
        <ToastContainer/>
        <div className="flex justify-between">
            <h1 className="text-left font-bold text-general-100 text-2xl mb-8">
                {loading && <div className="h-8"></div>}
                {!loading && <>{company.name} ({company.symbol})</>}
            </h1>
          <div className="cursor-pointer mt-4" onClick={handleToggleFavorite}>{isFavorite ? <StarActive/> : <StarInactive/>}</div>
        </div>
        <Panel className="mb-4">
          <Tabs tabs={tabs} onSelection={setTab}/>
        </Panel>
        {tab === 0 &&
            <OverviewTab 
                esgScore={esgScore}                
                company={company}
                loading={loading}
            />
        }
        {tab === 1 &&
        <EsgTab esgScore={esgScore} symbol={company.symbol}/>
        }
        {tab === 2 &&
        <FinanceTab company={company}/>
        }
        {tab === 3 &&
        <RiskTab risk1={company.risk_corporate_score} risk2={company.risk_market_score} symbol={company.symbol}/>
        }
         {tab === 4 &&
        <Panel>
          <div className="font-normal p-2" ><span className="font-semibold">Sector:</span> {company.sector?.name}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Industry:</span> {company.industry?.name}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Region:</span> {company.region}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Country:</span> {company.country?.name}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Market Cap:</span> {company.market_cap}</div>
          <div className="font-normal p-2" ><span className="font-semibold">CEO:</span> {company.ceo}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Number of Employees:</span> {company.employees}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Website:</span>{company.website}</div>
          <div className="font-normal p-2" ><span className="font-semibold">Description:</span> {company.description}</div>
        </Panel>
        }
        {tab === 5 &&
        <NewsTab company={company}/>
        }
        </div>
    );
}