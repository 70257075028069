
import { Company } from "./Company.jsx";
import { getList } from "./../../common/BackendProvider";
import { useEffect, useState } from 'react';
import {toast, ToastContainer} from "react-toastify";
import Table from "./../../layout/Table.jsx";
import { format } from "d3";
import { components } from "react-select";

export const BestPerformers = () => {
  const[companies, setCompanies] = useState([])
  const[loading, setLoading] = useState(true)
  useEffect(() => {
        setLoading(true)
        const sort = {field: 'financial_score', order: 'desc'}
        getList("companies/best-performers", {
          filter: {hasprice: 0}, 
          pagination:{page:1, perPage:7},
          sort: sort
        }).then((response) => {
            setCompanies(response.data.companies)
            setLoading(false)
        }).catch(
            (e) => {
                toast.error("Error while loading companies", {})
                setLoading(false)
            }
        )
  },[])

    const getColor = (d) => {
        if (d > 5) return 'bg-[#00CC00]';
        if (d > 2) return 'bg-[#7FFF00]';
        if (d > -1) return 'bg-[#FFFF00]';
        if (d > -4) return 'bg-[#FF7F00]';
        return 'bg-[#FF0000]';
    };

    const getComponent = (d) => {
        const color = getColor(d);
        if (d > 0) {
            d = "+" + d
        }
        return (
        <div className={`w-8 h-8 m-auto rounded-full flex justify-center ${color} text-center items-center`}>
            {d}
        </div>
        ) 
    }

    return (
        <div className=" w-full mt-8 gap-1 rounded-lg bg-white">
          <div className="text-lg font-bold mb-8 ml-6 mt-6">
            Best Performers
          </div>
          <div className="px-4">
            <Table
              columns={["Name","Last Price","ESG Score", "Total Score", "24h High", "24h Low"]}
              keys={[
                {name: 'name', type: 'text', format: (d) => (d), class: "text-left", link: "/company/{symbol}"},
                {name: 'last_price', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"},
                {name: 'esg_score', type: 'text', format: (d) => format(",.0f")(d), class: "text-right", link: "/company/{symbol}"},
                {name: 'total_score', type: 'text', format: (d) => format(",.0f")(d), class: "text-center", component: getComponent, link: "/company/{symbol}"},
                {name: 'high_24h', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"},
                {name: 'low_24h', type: 'text', format: (d) => format(",.2f")(d), class: "text-right", link: "/company/{symbol}"}
              ]}
              className={""}
              data={companies}
              readOnly={true}
              />
          </div>
          
        </div>
    )
}