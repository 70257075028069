import Title from '../../../../layout/Title';
import { News } from './News';
import { About } from './About';
import { Panel } from '../../../../layout/Panel';
import PieChartWithNeedle from '../../../../layout/components/PieChartWithNeedle';
import { StockChart } from './StockChart';

export const OverviewTab =({esgScore, company, loading}) => {
        const data = [
        { name: 'A', value: 20, color: '#FF0000' }, // Red
        { name: 'B', value: 20, color: '#FF7F00' }, // Orange
        { name: 'C', value: 20, color: '#FFFF00' }, // Blue
        { name: 'D', value: 20, color: '#7FFF00' }, // Light Green
        { name: 'E', value: 20, color: '#00CC00' }, // Green
    ];
    const riskScore = company.risk_corporate_score + company.risk_market_score
    const financialScore = company.financial_pe_score + company.financial_ae_score + company.financial_pv_score + company.financial_recommendation_score;
    const totalScore = esgScore.total + financialScore + riskScore;

    return(
        <div className="flex flex-col gap-4">
            <div className="lg:flex lg:flex-row gap-4">
                <Panel>
                    <div className="lg:flex">
                        <div className="flex flex-col">
                            <div className="text-center font-bold">Total Score {totalScore}</div>
                            <PieChartWithNeedle data={data} width={240} height={240} value={(totalScore + 20) * 2.5}/>
                        </div>
                    </div>
                </Panel>
                <Panel>
                    <Title>Stock Price</Title>
                    <div className="pt-4 w-full">
                        <StockChart company={company}/>
                    </div>
                </Panel>
            </div>
            <div className="lg:flex lg:flex-row">
                <div className='lg:w-2/3'>
                <News company={company} loading={loading}/>
                </div>
                <div className='lg:w-1/3 lg:ml-4' >
                <About company={company}/>
                </div>
            </div>
        </div>
    )
}